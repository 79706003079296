$font-stack: 'Montserrat', sans-serif;

.menu-details-container {
  background-color: rgba(0,0,0,0.8);
  color: white;
  text-align: center;
  width: 90%;
  margin: 10px auto;
  padding: 7px .8rem;
  font-size: 0.9rem;
  ul {
    padding: 0;
    list-style: none;
    text-align: center !important;
    font-family: $font-stack;
    li {
      padding-bottom: 10px;
      .price {
        font-size: 0.9rem !important; }
      p {
        margin: 0; }
      p:nth-of-type(odd), .extra-price {
        font-size: 10px; } } } }



@media only screen and (min-width: 769px) {
  .menu-details-wrapper__laptop {
    width: 60%;
    height: 100%;
    .menu-details-container {
      width: 90%;
      height: auto;
      margin: 5rem auto 0 auto;
      font-size: 1.3rem;
      ul li p:nth-of-type(odd) {
        font-size: 15px; } } } }
