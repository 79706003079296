.contact-main {
  height: 100vh;
  .contact-background {
    height: 100vh;
    h1 {
      padding-top: 70px;
      color: red;
      text-align: center;
      text-shadow: 1px 1px 2px black;
      font-size: 2.5rem; }
    .contact-info {
      p {
        display: flex;
        flex-direction: column;
        align-items: center; }
      p span {
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center; }
      p a {
        text-decoration: none;
        color: black; } }
    .iframe-container {
      height: 50vh;
      iframe {
        width: 100%;
        height: 28vh;
        margin: auto; } } } }

@media only screen and (min-width: 769px) {
  .contact-info {
    display: flex;
    justify-content: space-evenly;
    p {
      text-align: center; }
    p span {
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      p a {
        text-decoration: none;
        color: black; } } }
  .iframe-container {
    width: 90%;
    height: 50vh !important;
    margin: auto;
    iframe {
      width: 100% !important;
      height: 50vh !important;
      margin: auto !important; } } }
