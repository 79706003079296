footer {
  .footer__hours {
    text-align: center;
    .footer__hours__alignment {
      display: flex;
      justify-content: space-evenly;
      p {
        font-size: smaller;
        span {
          font-weight: bold;
          font-size: larger; } } }
    h1 {
      margin-bottom: 0; } }


  .footer__info {
    background-color: black;
    color: white;
    font-size: 0.6rem;
    text-align: center;
    div {
      display: flex;
      padding: 0 5px;
      justify-content: space-evenly;
      p {
        display: flex;
        flex-direction: column;
        span {
          font-weight: bold; }
        a {
          color: white;
          text-decoration: none; } } }
    .footer__copyright {
      text-align: center; } } }

@media only screen and (min-width: 796px) {
  .footer__hours__alignment {
    font-size: x-large; }

  .footer__info {
    padding: 7px 0;
    div {
      display: flex;
      flex-direction: column;
      p {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        margin: 0;
        span {
          font-weight: bold;
          font-size: larger;
          padding: 0 7px; }
        a {
          padding: 0 7px; } } }
    .footer__copyright {
      margin-top: 0; } } }
