main {
  padding: 0;
  margin: 0;
  width: 100vw;
  .background-image {
    display: grid;
    grid-template-rows: repeat(10, auto);
    grid-template-columns: repeat(4, 1fr);
    background: url("../../images/amie-johnson-VJXLzQi5TlE-unsplash.jpg") no-repeat center scroll;
    background-size: cover;
    height: 90vh;
    padding: 0;
    margin: 0; }
  .home-section {
    padding: 0 5px;
    h2 {
      text-align: center;
      font-size: 1.5rem; }
    p {
      text-align: center;
      width: 80%;
      margin: 0 auto 20px auto; } } }


@media only screen and (min-width: 769px) {
  .background-image {
    background: url("../../images/amie-johnson-VJXLzQi5TlE-unsplash.jpg") no-repeat center fixed !important;
    background-size: cover !important; } }
