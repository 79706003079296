.home-specials {
  display: flex;
  flex-direction: column;
  height: 120vh;
  background: black;
  color: white;
  padding: 0 10px;
  h1 {
    text-align: center;
    font-size: 24px; }
  h2 {
    font-size: 20px; }
  .item-price__div {
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 16px; } }
  .description__div {
    p {
      margin: 0 0 10px 0;
      padding: 0;
      font-size: 14px; } } }

@media only screen and (min-width: 769px) {
  .home-specials {
    article {
      border: 1px solid lightgrey;
      border-radius: 20px;
      box-shadow: 2px 2px 4px white;
      width: 60%;
      margin: auto;
      padding-bottom: 10px;
      .specials-items {
        width: 60%;
        margin: auto; } } } }
