.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box; }



.toggle-button:focus {
  outline: none; }

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: black; }

@media screen and (min-width: 769px) {
  .toggle-button {
    display: none; } }

