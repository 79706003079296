.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform .3s ease-out;
  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 5rem 23px;
    li {
      font-size: 2rem;
      margin: 1rem 0;
      a {
        text-decoration: none;
        color: black; }
      a:hover {
        color: red; } } } }

.open {
  transform: translateX(0); }
