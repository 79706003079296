$font-stack: 'Montserrat', sans-serif;

html {
  font-family: $font-stack;
  height: 100%;
  margin: 0;
  padding: 0;
  body {
    padding: 0;
    margin: 0;
    height: 100vh; } }
