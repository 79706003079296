main {
  padding: 0;
  margin: 0;
  min-height: 100vw;
  .liquor-store-background {
    background: url("../../images/john-hernandez-0-4zzoh3JXU-unsplash.jpg") no-repeat center scroll;
    background-size: cover;
    min-height: 100vh;
    h1 {
      padding-top: 70px;
      color: red;
      text-align: center;
      text-shadow: 1px 1px 2px black;
      font-size: 2rem; }
    .store__text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      background-color: rgba(0,0,0,0.8);
      color: white;
      width: 80%;
      margin: auto;
      padding: 15px;
      box-shadow: 3px 2px 5px black; } } }

@media only screen and (min-width: 769px) {
  main {
    min-height: unset; } }
